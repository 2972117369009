<template>
  <div class="userpersonal">
      <div class="header">
         <span class="left" @click="goback"><van-icon name="arrow-left"  /></span>

         <div class="btn" v-if="userInfo.userId != userId">
             <button class="bule" @click="attention" v-if="btnshow==false">关注</button>
             <button class="red" @click="openPopup" v-else>取关</button>
             <button class="grey" >私信</button>
         </div>

         <div class="perInfo">
            <!-- 头像 -->
            <div class="headPortrait" v-if="profile.memberData">
               <van-image
                  round
                  cover
                  fit="cover"
                  :src="profile.headImg ? profile.headImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
                />

                <img
                  src="~@image/svip.png"
                  alt=""
                  v-if="profile.memberData[0].level === 3"
                />
                <img
                  src="~@image/vip.png"
                  alt=""
                  v-else-if="profile.memberData[0].level === 2"
                />
                <img
                  src="~@image/my-vip.png"
                  alt=""
                  v-else-if="profile.memberData[0].level === 1"
                />
            </div>
            <p class="nickName">{{profile.nickName}}</p>
            <p class="introduction">{{profile.scontent}}</p>
         </div>
         <ul class="follow clearfix">
           <li>
             <p>{{profile.countFocus}}</p>
             <p>关注</p>
           </li>
           <li>
             <p>{{profile.countFans}}</p>
             <p>粉丝</p>
           </li>
           <li>
             <p>{{profile.countDynamic==null?"0":profile.countDynamic}}</p>
             <p>动态</p>
           </li>
           <li>
             <p>{{profile.countPraise==null?"0":profile.countPraise}}</p>
             <p>点赞</p>
           </li>
         </ul>
      </div>
     
      <van-tabs v-model="active" @change="onTabChange">
        <van-tab :title="item.title" v-for="item in tabs" :key="item.type" >
                <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
              <post-list2 v-for="(item,index) in list"
                        :key="index"
                         @jumpDetail="jumpDetail(item)"
                          @showOperation="showOperation(item,index)"
                          @handleshare="handleshare(item)"
                        :item="item"/>

            </van-list>
        </van-tab>
      </van-tabs>
      
       <van-popup :class="dataItem.postType !==3 ?'popup-operation':'popup_active'"  v-if="userInfo.userId != userId"  v-model="operation" position="bottom">
            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postCollect(dataItem,dataIndex)" v-show="dataItem.postType !==3" v-if="dataItem.collect !== true">
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="removeCollect(dataItem,dataIndex)" v-show="dataItem.postType !==3" v-else>
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postShield(dataItem)">
              <img src="../../assets/image/post-shield.png" alt=""/>
              <span>屏蔽</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postReport(dataItem)">
              <img src="../../assets/image/post-report.png" alt=""/>
              <!-- 投诉 -->
              <span>举报</span>
            </div>
    </van-popup>
        
         <van-popup :class="dataItem.postType !==3 ?'popup-operation':'popup_active'" v-else v-model="operation" position="bottom" >
           <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="postCollect(dataItem,dataIndex)" v-show="dataItem.postType !==3" v-if="dataItem.collect !== true">
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="removeCollect(dataItem,dataIndex)" v-show="dataItem.postType !==3" v-else>
              <img src="../../assets/image/post-collect.png" alt=""/>
              <span>取消收藏</span>
            </div>

            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="editor(dataItem)">
              <img src="../../assets/image/My-bianji.png" alt=""/>
              <span>编辑</span>
            </div>
            <div :class="dataItem.postType !==3?'popup_item':'active_item'" @click="delReport(dataItem,dataIndex)">
              <img src="../../assets/image/My-del.png" alt=""/>
              <span>删除</span>
            </div>
         </van-popup>
         
    </div>
</template>

<script>
import { getUserInfoByUserId,findContentByUserId,focusUser,delArticle,delBugOrSuggest } from '../../api/user';
import {Collection,removeCollect} from "../../api/community";
import PostList2 from "../community/PostList2";
import { Dialog } from 'vant';
import qs from "qs"
  export default {
    components:{
      PostList2
    },
     data(){
       return{
         active:'0',
         userId:'',
         userInfo:'',
         profile:'',
         list:'',
         pageNum: 1,
         pageSize: 10,
          //更多
         operation: false,
         loading: true,
         finished: false,
         btnshow:false,
         tabs: [
            {
              title: '全部',
              type: 0
            },
            {
              title: '活动',
              type: 1
            },
            {
              title: '帖子',
              type: 2
            },
          ],
          dataItem:'',
          dataIndex:''
       }
     },
     methods:{
         //取消收藏
        async removeCollect(item,index){
         const info = {
           targetId:item.id,
           type:item.postType
         }
         const result = await removeCollect(qs.stringify(info))
          if(result.code === 0){
           this.$toast("取消收藏!")
           this.operation = false
           this.dataList[index].collect = false
         }else{
           this.$toast(result.msg)
           this.operation = false
         }
      },
       
         jumpDetail(item){
              this.$router.push({
                name:'CommunitDetails',
                query:{
                  id:item.id,
                  type:item.postType,
                  tabType:this.active,
                  pageType:3,
                  userId:this.userId,
                }
              })
          },

       goback(){
         if(window.android){
            let obj = {
              action:'back'
            }
            window.android.postMessage(JSON.stringify(obj));
         }else if(window.webkit){
            let obj = {
              action:'back'
            }
            window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj));
         }else{
            this.$router.push({
              name:"Communityadmin"
            })
         }
       },
      
      //分享
    handleshare(item){
      let txt = "http://"+window.location.host+"/#/CommunitDetails?id="+item.id+"&type="+item.postType
      if(window.android){
            const obj = {
              action:'share',
              data:{
                type:3,
                content:txt,
              }
          }
          window.android.postMessage(JSON.stringify(obj));
      }else if(window.webkit){
         // ios 环境下
        const obj = {
          action: "share",
          data: {
            type: 3,
            content:txt,
          },
        };
        let jsonStr = JSON.stringify(obj);
        window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
      }
    },
    
         //收藏
      async postCollect(item){
         let obj = ""
         obj = item
         const info = {
           collectType:1,
           targetId:obj.id,
           type:obj.postType,
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast(result.msg)
           this.operation = false
           this.dataList[index].collect = true
         }
      },
      //举报
      postReport(item){
         sessionStorage.setItem("tabType",this.active)
         this.$router.push({
           name:'complaint',
           query:{
              id:item.id,
              type:item.postType,
              pageType:1,
           }
         })
      },
     //屏蔽
      async postShield(item){
         let obj = ""
         obj = item
         const info = {
           collectType:2,
           targetId:obj.id,
           type:obj.postType,
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast("屏蔽成功！")
           this.operation = false
           this.$router.go(0)
         }
      },

       onLoad() {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
          })

          if (this.active === 1 ){
            this.finished = true
            this.loading = false
            this.$toast.clear()
            return;
          }
          this.finished = false
          this.loading = true
          this.pageNum ++
          this.finContentById(this.tabs[this.active].type)
        },

        async attention(){
            const data = {
              authorAccount:this.profile.mobile
            }
            const result = await focusUser(qs.stringify(data))
            if(result.code === 0 ){
              this.getUserInfoById()
              if(this.btnshow == true){
                 this.$toast("已取消关注！")
              }else{
                 this.$toast("关注成功！")
              }
            }
        },

        onTabChange(){
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
          })
          this.list = []
          this.pageNum = 1
          this.loading = true
          this.finished = false
          this.finContentById(this.tabs[this.active].type)
        },

       showOperation(item,index){
          this.operation = true
          this.dataItem = item
          this.dataIndex = index
        },
       async getUserInfoById(){
          const data = {
              userId:this.userId
          }
          const result = await getUserInfoByUserId(qs.stringify(data))
          if(result.code === 0 ){
             this.profile = result.data
             this.btnshow = this.profile.focus
          }
       },
        //编辑
          editor(item){
             sessionStorage.setItem("pageType","3")
             sessionStorage.setItem("tabType",this.active)
             this.$router.push(`/addposts2/${item.postType}?id=${item.id}`)
          },

          async del(item,index){
           const info = {
                dismainId:item.id
           } 
          const res = await delArticle(info)
          if(res.code === 0 ){
              this.$toast("删除成功！")
              this.list.splice(index,1)
          }    
        },
        async delbug(item,index){
            const info = {
               id : item.id
            }
            const res = await delBugOrSuggest(qs.stringify(info))
             if(res.code === 0 ){
              this.$toast("删除成功！")
              this.list.splice(index,1)
             }    
        },
         //删除
        delReport(item,index){
            this.operation = false
            Dialog.confirm({
              title: '删除',
              message: '是否删除该帖子吗？',
              confirmButtonText:'删除',
              confirmButtonColor:'#4588FF',
            })
              .then(() => {
                // on confirm
               if(item.postType == 1 || item.postType == 2){
                  this.del(item,index)
               }else{
                  this.delbug(item,index)
               }
              })
              .catch(() => {
                // on cancel 
              });
       },
          

       async finContentById(type){
         
           const data = {
             userId:this.userId,
             pageNum: this.pageNum,
	           pageSize: this.pageSize,
             type:type,
           }
           const result = await findContentByUserId(data)
            if (result.code === 0) {
              this.$toast.clear()
              this.loading = false
              this.finished = false

              if (result.data.list.length === 0) {
                this.loading = false
                this.finished = true
              }

              if (result.data.isLastPage || result.data.pages <= this.pageNum) {
                this.finished = true
              }
              result.data.list.map(item =>{
                   if(item.pics.length >0){
                     item.pics =  item.pics.slice(0,4)
                   }
              })
              this.list = result.data.list
            }
       },
       openPopup(){
         Dialog.confirm({
            title: '提示',
            message: '确定不再关注？',
            confirmButtonColor:'#4588FF',
            width:"250px"
          })
            .then(() => {
               this.attention()
            })
            .catch(() => {
              // on cancel
            });
       },
     },
     created(){
       this.userId = JSON.parse(this.$route.query.id)
       this.getUserInfoById()
       this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
       JSON.parse(this.userInfo.userId)
       if(sessionStorage.getItem("tabType")){
            this.active = parseInt(sessionStorage.getItem("tabType"))
            sessionStorage.removeItem("tabType")
            this.onTabChange()
            return
       }
      
       this.finContentById(0)
     }
  }
</script>

<style lang="scss" scoped>
.userpersonal{
   .header{
     position: relative;
     border-bottom: 10px #F4F4F4 solid;
     padding-bottom: 20px;
     .left{
       position: absolute;
       left: 15px;
       top: -14px;
       width: 50px;
       height: 100px;
       ::v-deep .van-icon{
         font-size: 18px;
       }
     }
     .btn{
       position: absolute;
       right: 15px;
       top: 20px;
       z-index: 99;
       button{
         display: block;
         width: 63px;
         height: 27px;
         border-radius: 14px;
       }
       .bule{
          background: #3783F3;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
       }
       .grey{
          background: #FFFFFF;
          border: 1px solid #000000;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 10px;
       }
       .red{
          background: #FFFFFF;
          border: 1px solid #000000;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
       }
     }
     .perInfo{
       text-align: center;
       transform: translateY(45px);
         .headPortrait{
           height: 75px;
           width: 75px;
           margin-left: 50%;
           transform: translateX(-50%);
           position: relative;
           img:first-of-type{
             width: 100%;
             height: 100%;
             border-radius: 50%;
           }
           img:last-of-type{
             width: 17px;
             height: 17px;
             position: absolute;
             bottom: -2px;
             right: 6px;
           }
         }
         .nickName{
           font-size: 17px;
           font-family: PingFang SC;
           font-weight: bold;
           color: #333333;
           margin-top: 10px;
         }
         .introduction{
           font-size: 14px;
           font-family: PingFang SC;
           font-weight: 500;
           color: #999999;
           margin-top: 10px;
         }
     }
     .follow{
       margin-top: 68px;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-left: 42px;
       margin-right: 42px;
       li{
         flex: 1;
         text-align: center;
         p:first-of-type{
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
         }
         p:last-of-type{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
         }
       }
     }
   }
   ::v-deep .van-tab.van-tab--active{
     color: #4587FF;
   }
   ::v-deep .van-tab__text{
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
   }
   ::v-deep .van-tabs__line{
      height: 3px;
      width: 25px;
      background: #4587FF
   }
}
.clearfix:after{
  content: "";
  clear: both;
  display: block;
}
.popup-operation{
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup_item{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
}
.popup_item > img{
  width: 20px;
  transform: translateY(-2px);
  height: 20px;
}
.popup_item > span{
  margin-left: 20px;
}
.popup_active{
  height:20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .active_item{
    font-size: 14px;
    margin-left: 20px;
    margin-top: 20px;
    flex: 1;
    img{
      width: 20px;
      height: 20px;
       transform: translateY(-2px);
    }
    span{
      margin-left: 20px;
    }
  }
}
::v-deep .van-image--round{
    width: 100%;
    height: 100%;
}
</style>